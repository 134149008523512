import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
import { CourseAPI } from '@/api/course.api'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { Country } from '@/models/countries/classes/Country'
import { Course } from '@/models/course/classes/Course'
import { CustomFilter, FilterProps } from '@/models/custom-filters/classes/CustomFilter'
import { CourseFilters, ICourseFilters } from '@/models/custom-filters/filters/CourseFilters'
import { TableName, TypeFilter } from '@/models/custom-filters/enum'
import { CourseType } from '@/models/enums'
import { DataTablePageEvent, DataTableSortEvent } from '@/models/interfaces/primevue'
import { computed, onMounted, ref, Ref, watch } from 'vue'
import { useCustomFilters } from './useCustomFilters'
import { Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import { useToast } from 'primevue/usetoast'
import { CourseStatus } from '@/models/course/enums'
import { StaffMemberSummary } from '@/models/course/dto/staffMemberSummary.dto'
import { StaffMemberAPI } from '@/api/staffMember.api'
import { ColumnFields } from '@/models/components/tables/enums'
import { Column } from '@/models/components/tables/Column'

interface CourseListState {
  firstVal: Ref<number>
  loading: Ref<boolean>
  loadingStaffMemberSummaries: Ref<boolean>
  allCoursePlans: Ref<CoursePlan[]>
  filters: Ref<CourseFilters>
  courses: Ref<Course[]>
  staffMemberSummaryByCourse: Ref<Map<string, StaffMemberSummary>>
  totalRecords: Ref<number>
  coursesQtyNumber: Ref<number>
  searchTxt: Ref<string>
  sortFieldFilter: Ref<string|null>
  sortOrderFilter: Ref<number>
  recordsLimit: number
  courseFilters: Ref<CustomFilter[]>
  courseViews: Ref<CustomFilter[]>
  selectedCustomFilter: Ref<CustomFilter | null>,
  selectedCustomView: Ref<CustomFilter | null>,
  displayCreateFilter: Ref<{ show: boolean, type: string }>
  filterNameInput: Ref<string>
  isFiltered: Ref<boolean>
  toggleCreateFilter: (type: TypeFilter | null) => void
  removeActiveFilter: (type: TypeFilter) => void
  onPage: (e: DataTablePageEvent) => void
  onSort: (e: DataTableSortEvent) => void
  updateFilterListened: (f: FilterProps) => void
  saveFilters: (type: TypeFilter) => Promise<void>
  showConfirmDeleteFilter: (cf: CustomFilter) => void
  cleanAndUpdateFiltersBySelector: (f: CustomFilter) => void
  updateSearchTxt: (val: string) => void
  allColumns: Map<ColumnFields, Column>,
  changeSelectedColumns: (keys: ColumnFields[]) => void
}

export const useCourseList = (): CourseListState => {
  // Active filter
  const filters = ref<CourseFilters>(new CourseFilters())
  const selectedCountries = computed<Country[]>(() => filters.value.countries.value as Country[])
  const selectedCoursePlans = computed<CoursePlan[]>(() => filters.value.coursePlans.value as CoursePlan[])
  const courseTypeFilter = computed<CourseType[]>(() => filters.value.courseType.value as CourseType[])
  const selectedStartMonth = computed<string>(() => filters.value.startMonth.value as string)
  const selectedEndMonth = computed<string>(() => filters.value.endMonth.value as string)
  const searchTxt = ref('')
  const teacherAssignedArray = computed<boolean[]>(() => filters.value.teacherAssigned.value as boolean[])
  const tutorsCompletedArray = computed<boolean[]>(() => filters.value.tutorsCompleted.value as boolean[])
  const coordinatorAssignedArray = computed<boolean[]>(() => filters.value.coordinatorAssigned.value as boolean[])
  const isSoldOutFlag = computed<boolean>(() => filters.value.isSoldOut.value as boolean)
  const isCancelledFlag = computed<boolean>(() => filters.value.isCancelled.value as boolean)
  const selectedCourseDays = computed<number[]>(() => filters.value.days.value as number[])
  const allColumns: Map<ColumnFields, Column> = new Map([
    [ColumnFields.Id, { field: ColumnFields.Id, header: 'Comisión' }],
    [ColumnFields.Name, { field: ColumnFields.Name, header: 'Curso' }],
    [ColumnFields.Start, { field: ColumnFields.Start, header: 'Inicio' }],
    [ColumnFields.End, { field: ColumnFields.End, header: 'Fin' }],
    [ColumnFields.DaysAndHours, { field: ColumnFields.DaysAndHours, header: 'Días y horarios' }],
    [ColumnFields.StudentCapacity, { field: ColumnFields.StudentCapacity, header: 'Inscriptos' }],
    [ColumnFields.StaffAssignment, { field: ColumnFields.StaffAssignment, header: 'Staff' }],
    [ColumnFields.Status, { field: ColumnFields.Status, header: 'Estado' }]
  ])

  const changeSelectedColumns = (keys: ColumnFields[]):void => {
    filters.value.columns = { value: keys }
  }

  const updateSearchTxt = (val: string): void => {
    searchTxt.value = val
  }
  const searchTxt$ = new Observable<string>(subscriber => {
    watch(searchTxt, () => subscriber.next(searchTxt.value))
  })
  // UI
  const toast = useToast()
  const loading = ref<boolean>(false)
  const loadingStaffMemberSummaries = ref<boolean>(false)
  // Table config
  const courses = ref<Course[]>([])
  const staffMemberSummaryByCourse = ref<Map<string, StaffMemberSummary>>(new Map())
  const firstVal = ref(0)
  const recordsLimit = 15
  const sortFieldFilter = ref<string|null>(null)
  const sortOrderFilter = ref<number>(1)
  const totalRecords = ref<number>(0)
  const allCoursePlans = ref<CoursePlan[]>([])
  const coursesQtyNumber = ref<number>(0)
  const updateList = async () => {
    try {
      loading.value = true
      coursesQtyNumber.value = await CourseAPI.findQtyByStatus(CourseStatus.NotStarted)
      const response = await CourseAPI.findAllByStatus(
        {
          status: CourseStatus.NotStarted as string,
          offset: firstVal.value,
          limit: recordsLimit,
          search: encodeURIComponent(searchTxt.value.trim()),
          ...(selectedCountries.value) && { countries: selectedCountries.value.map(c => c.code).join(',') },
          ...(courseTypeFilter.value && { courseType: courseTypeFilter.value.join(',') }),
          ...(selectedStartMonth.value && { startMonth: selectedStartMonth.value }),
          ...(selectedEndMonth.value && { endMonth: selectedEndMonth.value }),
          ...(selectedCoursePlans.value) && { coursePlans: selectedCoursePlans.value.map(c => c.information.name).join(',') },
          ...(sortFieldFilter.value && sortOrderFilter.value && { sortField: sortFieldFilter.value, order: sortOrderFilter.value }),
          ...(teacherAssignedArray.value && { teacherAssigned: teacherAssignedArray.value.map(b => String(b)).join(',') }),
          ...(tutorsCompletedArray.value && { tutorsCompleted: tutorsCompletedArray.value.map(b => String(b)).join(',') }),
          ...(coordinatorAssignedArray.value && { coordinatorAssigned: coordinatorAssignedArray.value.map(b => String(b)).join(',') }),
          ...(isSoldOutFlag.value && { isSoldOutFlag: isSoldOutFlag.value }),
          ...(isCancelledFlag.value && { isCancelledFlag: isCancelledFlag.value }),
          ...(selectedCourseDays.value && { courseDays: selectedCourseDays.value.map(d => d.toString()).join(',') })
        }
      )
      courses.value = response.data
      totalRecords.value = response.count
    } catch (error) {
      toast.add({
        summary: ToastSummaries.Error,
        detail: 'Ocurrió un error al cargar las comisiones',
        severity: ToastSeverities.Error,
        life: ToastLife.Default
      })
      console.error(error)
    } finally {
      loading.value = false
      loadingStaffMemberSummaries.value = true
    }
  }
  const findAllCoursePlansToSelector = async () => {
    try {
      loading.value = true
      allCoursePlans.value = await CoursePlanAPI.findAll()
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }
  const {
    findAllCustomFilters,
    toggleCreateFilter,
    saveFilters,
    showConfirmDeleteFilter,
    removeActiveFilter,
    updateFilterListened,
    cleanAndUpdateFiltersBySelector,
    allCustomFilters,
    allCustomViews,
    isFiltered,
    filterNameInput,
    selectedCustomFilter,
    selectedCustomView,
    displayCreateFilter
  } = useCustomFilters({
    loading: loading,
    tableName: TableName.PlanningCoursesView,
    filtersListened: filters,
    cleanFilter: new CourseFilters({ columns: { value: (Array.from(allColumns.values()) as Column[]).map(c => c.field as ColumnFields) } } as ICourseFilters) as unknown as FilterProps
  })
  onMounted(async () => {
    findAllCoursePlansToSelector()
    findAllCustomFilters()
    updateList()
  })
  watch(courses, async () => {
    if (loadingStaffMemberSummaries.value) {
      courses.value.map(async (c) => {
        try {
          const summary = await StaffMemberAPI.findStaffMemberSummary(c._id)
          staffMemberSummaryByCourse.value.set(c._id, summary)
        } catch (e) {
          console.error(e)
        }
      })
    }
    loadingStaffMemberSummaries.value = false
  })
  watch(filters, () => {
    firstVal.value = 0
    updateList()
  })
  watch(searchTxt, () => {
    searchTxt$.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      tap(() => (firstVal.value = 0))
    ).subscribe(updateList)
  })

  return {
    firstVal,
    removeActiveFilter,
    coursesQtyNumber,
    allCoursePlans,
    filters,
    loading,
    loadingStaffMemberSummaries,
    courses,
    staffMemberSummaryByCourse,
    searchTxt,
    totalRecords,
    sortFieldFilter,
    sortOrderFilter,
    filterNameInput,
    recordsLimit,
    courseFilters: allCustomFilters,
    courseViews: allCustomViews,
    selectedCustomFilter,
    selectedCustomView,
    displayCreateFilter,
    toggleCreateFilter,
    isFiltered,
    onPage: (e: DataTablePageEvent) => {
      firstVal.value = e.page * recordsLimit
      updateList()
    },
    onSort: (e: DataTableSortEvent) => {
      sortFieldFilter.value = e.sortField as string
      sortOrderFilter.value = e.sortOrder as number
      updateList()
    },
    updateFilterListened,
    saveFilters,
    showConfirmDeleteFilter,
    cleanAndUpdateFiltersBySelector,
    updateSearchTxt,
    allColumns,
    changeSelectedColumns
  }
}
