
import { computed, defineComponent, onBeforeMount, onMounted, PropType, Ref, ref, toRefs, watch } from 'vue'

import { CoursePerformanceColumnsWidth, FormatDates, CSSColors } from '@/models/enums'
import { EducationRouteNames } from '@/router/route-names'
import { Country } from '@/models/countries/classes/Country'
import { CourseFiltersProps } from '@/models/custom-filters/filters/enums'
import CountrySelector from '@/components/courses/selectors/CountrySelector.vue'
import CoursePlanSelector from '@/components/educationPlans/selectors/CoursePlanSelector.vue'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { Course } from '@/models/course/classes/Course'
import { CourseHours, ConfigurationTag, CourseStatus } from '@/models/course/enums'
import { isLastItem } from '@/utils/arrays'
import { StaffMemberSummary } from '@/models/course/dto/staffMemberSummary.dto'
import { CourseFilters, ICourseFilters } from '@/models/custom-filters/filters/CourseFilters'
import { DateTime } from 'luxon'
import { ColumnFields } from '@/models/components/tables/enums'
import { Column } from '@/models/components/tables/Column'

export default defineComponent({
  props: {
    courses: {
      type: Array as PropType<Course[]>,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isLoadingStaffMemberSummaries: {
      type: Boolean,
      default: false
    },
    allCoursePlans: {
      type: Array as PropType<CoursePlan[]>,
      required: true
    },
    recordsPerPage: {
      type: Number,
      default: 15
    },
    firstRecord: {
      type: Number,
      default: 0
    },
    totalRecords: {
      type: Number,
      detault: 0
    },
    customFilterActive: {
      type: Object as PropType<CourseFilters>,
      require: false
    },
    staffMemberSummaryByCourse: {
      type: Map as PropType<Map<string, StaffMemberSummary>>,
      require: true
    },
    allColumns: {
      type: Map as PropType<Map<ColumnFields, Column>>,
      require: true
    },
    selectedColumns: {
      type: Array as PropType<ColumnFields[]>,
      require: true
    }
  },
  components: {
    CountrySelector,
    CoursePlanSelector
  },
  emits: ['updateFilters'],
  setup (props, { emit }) {
    const { customFilterActive, allColumns, selectedColumns } = toRefs(props)
    const filters = ref<ICourseFilters>({
      countries: { value: null },
      startMonth: { value: null },
      endMonth: { value: null },
      courseType: { value: null },
      coursePlans: { value: null },
      teacherAssigned: { value: null },
      tutorsCompleted: { value: null },
      coordinatorAssigned: { value: null },
      isSoldOut: { value: null },
      isCancelled: { value: null },
      days: { value: null },
      columns: { value: null }
    } as ICourseFilters)

    const matchFilterWithCustomFilterActive = () => {
      filters.value.countries.value = customFilterActive.value?.countries.value ?? null
      filters.value.startMonth.value = customFilterActive.value?.startMonth.value ? new Date(customFilterActive.value?.startMonth.value as string) : null
      filters.value.endMonth.value = customFilterActive.value?.endMonth.value ? new Date(customFilterActive.value?.endMonth.value as string) : null
      filters.value.courseType.value = customFilterActive.value?.courseType.value ?? null
      filters.value.coursePlans.value = customFilterActive.value?.coursePlans.value ?? null
      filters.value.teacherAssigned.value = customFilterActive.value?.teacherAssigned.value ?? null
      filters.value.tutorsCompleted.value = customFilterActive.value?.tutorsCompleted.value ?? null
      filters.value.coordinatorAssigned.value = customFilterActive.value?.coordinatorAssigned.value ?? null
      filters.value.isSoldOut.value = customFilterActive.value?.isSoldOut.value ?? null
      filters.value.isCancelled.value = customFilterActive.value?.isCancelled.value ?? null
      filters.value.days.value = customFilterActive.value?.days.value ?? null
      filters.value.columns.value = customFilterActive.value?.columns.value
        ? customFilterActive.value?.columns.value
        : allColumns.value
          ? Array.from(allColumns.value.keys())
          : null
    }

    onBeforeMount(matchFilterWithCustomFilterActive)
    watch(selectedColumns, matchFilterWithCustomFilterActive)

    const updateFilters = (f: ICourseFilters): void => {
      emit('updateFilters', f)
    }

    const cleanFilters = (fp: CourseFiltersProps[]): void => {
      fp.map(p => {
        (filters.value[p]).value = null
        return 0
      })
      emit('updateFilters', filters.value)
    }

    return {
      updateFilters,
      cleanFilters,
      CourseFiltersProps,
      filters,
      CoursePerformanceColumnsWidth,
      EducationRouteNames,
      Country,
      CourseHours,
      FormatDates,
      isLastItem,
      CSSColors,
      ConfigurationTag,
      CourseStatus,
      ColumnFields
    }
  }
})
