import { CSSColors } from '@/models/enums'
import { PersonRoles } from '@/models/person/enums'
import { StaffUnenrollmentReason, StaffUnenrollmentType } from '../enums'

export interface IStaffMemberTerm {
  start: Date
  end: Date
}

export interface IStaffMemberProfile {
  name: string
  lastName: string
  avatar: string
  country: string
}

export interface IStaffMemberUnenrollment {
  unenroll: boolean
  reason: StaffUnenrollmentReason[]
  comment: string
  date: string
  author: string
  type: StaffUnenrollmentType
  enabled: boolean
}

export interface IStudyGroup {
  alias: string
  members: string[]
  powerBiAccount?: string
  powerBiPass?: string
}

export interface IStaffMemberLinks {
  slack: string
  chatChannel: string
  chatChannelId: string
}

export interface IStaffMemberTutoringGroup {
  students: string[]
  links: IStaffMemberLinks
  groups: IStudyGroup[]
}

export interface IStaffMemberUser {
  personId: string
  courseId: string
  role: PersonRoles
}

export interface IStaffMemberStatus {
  isSubstitute: boolean
  unenroll: boolean
}

export interface IStaffMemberTutoringStatus {
  assistance: string
  afterClass: string
}

export interface IStaffMemberReviewStatus {
  reviewed: number
  unreviewed: number
}

interface IStaffMemberBuilder {
  reset(): void
  buildUser(user: IStaffMemberUser): IStaffMemberBuilder
  buildProfile(profile: IStaffMemberProfile): IStaffMemberBuilder
  buildStatus(status: IStaffMemberStatus): IStaffMemberBuilder
  buildTutoringGroup(tutoring: IStaffMemberTutoringGroup): IStaffMemberBuilder
  buildTerm(term: IStaffMemberTerm): IStaffMemberBuilder
  buildUnenrollment(unenrollment: IStaffMemberUnenrollment): IStaffMemberBuilder
  buildTutoringStatus(tutoringStatus: IStaffMemberTutoringStatus): IStaffMemberBuilder
  buildReviewStatus(reviewStatus: IStaffMemberReviewStatus): IStaffMemberBuilder
}

export interface IStaffMember {
  user?: IStaffMemberUser | null
  profile?: IStaffMemberProfile | null
  status?: IStaffMemberStatus | null
  tutoringGroup?: IStaffMemberTutoringGroup | null
  term?: IStaffMemberTerm | null
  unenrollment?: IStaffMemberUnenrollment | null
  tutoringStatus?: IStaffMemberTutoringStatus | null
  reviewStatus?: IStaffMemberReviewStatus | null

}

export class StaffMember implements IStaffMemberBuilder, IStaffMember {
  user?: IStaffMemberUser | null
  profile?: IStaffMemberProfile | null
  status?: IStaffMemberStatus | null
  tutoringGroup?: IStaffMemberTutoringGroup | null
  term?: IStaffMemberTerm | null
  unenrollment?: IStaffMemberUnenrollment | null
  tutoringStatus?: IStaffMemberTutoringStatus | null
  reviewStatus?: IStaffMemberReviewStatus | null

  constructor () {
    this.reset()
  }

  public reset (): void {
    this.user = null
    this.profile = null
    this.status = null
    this.tutoringGroup = null
    this.term = null
    this.unenrollment = null
    this.tutoringStatus = null
    this.reviewStatus = null
  }

  buildUser (user: IStaffMemberUser): StaffMember {
    this.user = user
    return this
  }

  buildProfile (profile: IStaffMemberProfile): StaffMember {
    this.profile = profile
    return this
  }

  buildStatus (status: IStaffMemberStatus): StaffMember {
    this.status = status
    return this
  }

  buildTerm (term: IStaffMemberTerm): StaffMember {
    this.term = term
    return this
  }

  buildTutoringGroup (tutoring: IStaffMemberTutoringGroup): StaffMember {
    this.tutoringGroup = tutoring
    return this
  }

  buildUnenrollment (unenrollment: IStaffMemberUnenrollment): StaffMember {
    this.unenrollment = unenrollment
    return this
  }

  buildTutoringStatus (tutoringStatus: IStaffMemberTutoringStatus): StaffMember {
    this.tutoringStatus = tutoringStatus
    return this
  }

  buildReviewStatus (reviewStatus: IStaffMemberReviewStatus): StaffMember {
    this.reviewStatus = reviewStatus
    return this
  }

  get fullName (): string {
    return this.profile?.name + ' ' + this.profile?.lastName
  }

  static getTeacherStatus (staffMember: StaffMember): Record<string, string> {
    return staffMember.user?.role === PersonRoles.Teacher && !staffMember.status?.unenroll && !staffMember.status?.isSubstitute
      ? { name: 'Titular', color: CSSColors.GreenBrain }
      : staffMember.user?.role === PersonRoles.Teacher && staffMember.status?.unenroll
        ? { name: 'Baja', color: CSSColors.Gray700 }
        : staffMember.user?.role === PersonRoles.Teacher && !staffMember.status?.unenroll && staffMember.status?.isSubstitute
          ? { name: 'Suplente', color: CSSColors.LightblueBrain }
          : staffMember.user?.role === PersonRoles.Coordinator && !staffMember.status?.unenroll
            ? { name: 'Coordinador', color: CSSColors.YellowBrain }
            : { name: 'Reemplazo', color: CSSColors.Gray700 }
  }

  static getTutorStatus (staffMember: StaffMember): Record<string, string> {
    return staffMember.user?.role === PersonRoles.Tutor && !staffMember.status?.unenroll
      ? { name: 'Activo', color: CSSColors.GreenBrain }
      : { name: 'Baja', color: CSSColors.RedBrain }
  }

  static sortByUnenroll (array: StaffMember[]): StaffMember[] {
    return array.sort((x, y) => x.status?.unenroll === y.status?.unenroll ? 0 : !x.status?.unenroll ? -1 : 1)
  }
}
