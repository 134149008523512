
import { TypeFilter } from '@/models/custom-filters/enum'
import { defineComponent, computed } from 'vue'
import FilterConfiguration from './components/FilterConfiguration.vue'
import PlanningCoursesTable from './components/PlanningCoursesTable.vue'
import { useCourseList } from './hooks/useCourseList'

export default defineComponent({
  components: {
    PlanningCoursesTable,
    FilterConfiguration
  },
  setup () {
    const {
      firstVal,
      loading,
      loadingStaffMemberSummaries,
      allCoursePlans,
      filters,
      courseFilters,
      courseViews,
      selectedCustomFilter,
      selectedCustomView,
      courses,
      staffMemberSummaryByCourse,
      totalRecords,
      isFiltered,
      searchTxt,
      coursesQtyNumber,
      displayCreateFilter,
      toggleCreateFilter,
      onPage,
      onSort,
      recordsLimit,
      updateFilterListened,
      filterNameInput,
      saveFilters,
      showConfirmDeleteFilter,
      cleanAndUpdateFiltersBySelector,
      removeActiveFilter,
      updateSearchTxt,
      allColumns,
      changeSelectedColumns
    } = useCourseList()
    const dialogText = computed(() => {
      const isFilter = displayCreateFilter.value.type === TypeFilter.Filter
      return ({
        title: isFilter ? 'Guardar filtro' : 'Guardar vista',
        newCustomFilter: isFilter ? !selectedCustomFilter.value : !selectedCustomView.value,
        placeholder: isFilter ? 'Nombre de filtro' : 'Nombre de la vista',
        updatingTxt: isFilter ? `Actualizando filtro "${selectedCustomFilter.value?.name}"` : `Actualizando filtro "${selectedCustomView.value?.name}"`,
        disabledBtn: isFilter ? (!filterNameInput.value && !selectedCustomFilter.value) : (!filterNameInput.value && !selectedCustomView.value)
      })
    })
    return {
      courses,
      staffMemberSummaryByCourse,
      saveFiltersTypeFilter: (type: TypeFilter = TypeFilter.Filter) => saveFilters(type),
      searchTxt,
      courseFilters,
      courseViews,
      selectedCustomFilter,
      selectedCustomView,
      coursesQtyNumber,
      updateFilterListened,
      showConfirmDeleteFilter,
      displayCreateFilter,
      toggleCreateFilter,
      filterNameInput,
      filters,
      cleanAndUpdateFiltersBySelector,
      allCoursePlans,
      loading,
      loadingStaffMemberSummaries,
      totalRecords,
      recordsLimit,
      firstVal,
      isFiltered,
      onPage,
      onSort,
      removeActiveFilter,
      updateSearchTxt,
      allColumns,
      changeSelectedColumns,
      dialogText
    }
  }
})
