import { Api } from './api'
import { CRUD } from './crud'
import {
  StaffMember,
  IStaffMember,
  IStaffMemberUser,
  IStaffMemberProfile,
  IStaffMemberTerm,
  IStaffMemberStatus,
  IStaffMemberTutoringGroup,
  IStaffMemberUnenrollment,
  IStaffMemberTutoringStatus,
  IStaffMemberReviewStatus
} from '@/models/course/builder/staffMember.builder'
import { Formats } from '@/utils/enums'
import {
  IStaffMemberSummary,
  StaffMemberSummary
} from '@/models/course/dto/staffMemberSummary.dto'

class StaffMemberApi implements CRUD<StaffMember> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  async findAll(
    courseId: string,
    format: Formats = Formats.Middle
  ): Promise<StaffMember[]> {
    const dtoStaffMembers = await Api.get<IStaffMember[]>(
      `${this.baseURL}/v1/courses/${courseId}/staff`,
      { format: format }
    )
    return dtoStaffMembers.map((sm) => {
      const staffMember = new StaffMember()
        .buildUser(sm.user as IStaffMemberUser)
        .buildProfile(sm.profile as IStaffMemberProfile)
        .buildTerm(sm.term as IStaffMemberTerm)
        .buildStatus(sm.status as IStaffMemberStatus)
        .buildTutoringGroup(sm.tutoringGroup as IStaffMemberTutoringGroup)
        .buildUnenrollment(sm.unenrollment as IStaffMemberUnenrollment)
        .buildTutoringStatus(sm.tutoringStatus as IStaffMemberTutoringStatus)
        .buildReviewStatus(sm.reviewStatus as IStaffMemberReviewStatus)
      return staffMember
    })
  }

  async findStaffMemberSummary(courseId: string): Promise<StaffMemberSummary> {
    const dtoStaffMemberSummary = await Api.get<IStaffMemberSummary>(
      `${this.baseURL}/v1/courses/${courseId}/staff/summary`
    )
    return new StaffMemberSummary(dtoStaffMemberSummary)
  }
}

export const StaffMemberAPI = new StaffMemberApi()
