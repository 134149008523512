import { IStaffMember, IStaffMemberProfile, IStaffMemberReviewStatus, IStaffMemberStatus, IStaffMemberTerm, IStaffMemberTutoringGroup, IStaffMemberTutoringStatus, IStaffMemberUnenrollment, IStaffMemberUser, StaffMember } from '../builder/staffMember.builder'

export interface IStaffMemberSummary {
  teachers: IStaffMember[]
  tutors: number
  hasCoordinator: boolean
}

export class StaffMemberSummary {
  teachers: StaffMember[]
  tutors: number
  hasCoordinator: boolean

  constructor (dto: IStaffMemberSummary) {
    this.teachers = dto.teachers.map(t => {
      const sm = new StaffMember()
        .buildUser(t.user as IStaffMemberUser)
        .buildProfile(t.profile as IStaffMemberProfile)
        .buildTerm(t.term as IStaffMemberTerm)
        .buildStatus(t.status as IStaffMemberStatus)
        .buildTutoringGroup(t.tutoringGroup as IStaffMemberTutoringGroup)
        .buildUnenrollment(t.unenrollment as IStaffMemberUnenrollment)
        .buildTutoringStatus(t.tutoringStatus as IStaffMemberTutoringStatus)
        .buildReviewStatus(t.reviewStatus as IStaffMemberReviewStatus)
      return sm
    })
    this.tutors = dto.tutors
    this.hasCoordinator = dto.hasCoordinator
  }
}
